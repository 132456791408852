(function ($, win, doc) {
  $(function () {
    $(doc).on("click", ".aria-toggler", toggle);
  });

  function toggle() {
    // @todo: may control multiple items.
    var controls = this.getAttribute("aria-controls"),
      pressed = this.getAttribute("aria-pressed") === "true",
      elt = doc.getElementById(controls);
    this.setAttribute("aria-pressed", !pressed);
    if (elt) {
      elt.setAttribute("aria-hidden", !!pressed);
      // We might be able to animate it better with a-offscreen than with hidden.
      $(elt).toggleClass("a-offscreen", !!pressed);
    }
  }
})(jQuery, window, document);
