/* dialog:
 *
 * handle plugins the simplest of ways
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  // $(function(){
  //   $('.dialog-mask').attr('hidden', true);
  // });

  $(doc).on("keydown", function (ev) {
    if (ev.keyCode === 27)
      // ESCAPE
      win.location.hash = "";
  });

  $(doc).on("click touchstart", ".dialog-mask", function () {
    win.location.hash = "";
  });

  $(doc).on("click touchstart", ".dialog-box", function (ev) {
    if (ev.target.nodeName === "A") {
      ev.stopPropagation();
      return true;
    }
    return false;
  });
})(jQuery, window, document);
