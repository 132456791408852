import { bb } from "$json";
/* has-no-prior:
 *
 * Set body.hasNoPrior when the current graph.node is a member of a
 * predefined set, and unset it when isn't.
 *
 * Author: Niels Giesen
 *
 * Copyright (C) 2011, 2012, 2014, 2015 by Berkeley Bridge
 *
 */

(function ($) {
  // List of nodes that don't allow to go back
  var nodes = [
    "checkdeps.incompleet",
    "verzoeker.hoeveelverz",
    "s5_grondslaggraaf.s5_grondslag_vordering",
    "advocaat.s0_advocaat",
    "gerekwestreerde.gerekwestreerden",
    "verweer.s6_verweer_schuldenaar",
    "beslagobjecten.objecten",
    "aanvullend.s11_prop_en_subs",
    "rekest.objecten"
  ];

  $(document).on(
    "bb:postHandleData",

    function (event, data) {
      var noprior;
      if (data && data.groups) {
        noprior =
          $.grep(data.groups, function (group) {
            return group.current && $.inArray(group.name, nodes) > -1;
          }).length > 0;

        bb.Mode.toggle("hasNoPrior", data.hasprevious == false || noprior);
      }
    }
  );
})(jQuery);
