/* expand-next:
 *
 * Expand/hide next (non-checkbox) elements when a checkbox is
 * checked/unchecked
 *
 * Author: Niels Giesen
 * Copyright 2011, 2014, 2017 Berkeley Bridge
 *
 */
(function ($, d) {
  $(function () {
    $(d).on("bb:postHandleData", function (event, data) {
      $(":input[data-type=checkbox]:not(.bbm-no-hide)")
        .filter(":not(:checked)")
        .parents(".bb-questionlabelgroup")
        .nextUntil(".bb-itype-checkbox, .bb-g-dont-hide, .bb-text")
        .hide();
    });

    $(d).on(
      "change",
      ":input[data-type=checkbox]:not(.bbm-no-hide)",
      function () {
        var checked = $(this).prop("checked");
        $(this)
          .parents(".bb-questionlabelgroup")
          .nextUntil(".bb-itype-checkbox, .bb-g-dont-hide, .bb-text")
          .slideToggle(checked);
      }
    );
  });
})(jQuery, document);
