import { bb } from "$json";
/* questionlabelgroup-wrapping-input:
 *
 * Next-generation questionlabelgroup plugin.
 *
 * It will eventually probably replace the default implementation.
 *
 * Author: Niels Giesen
 * Copyright 2013, 2017 Berkeley Bridge
 *
 */
(function ($) {
  $(document).off("bb:questionlabelgroup");

  var layoutmap = {
    edit: "aside",
    numedit: "aside",
    checkbox: "aside",
    datetimepicker: "aside",
    combobox: "aside",
    linklabel: "below",
    listlabel: "below",
    memo: "below",
    checkmultilist: "below",
    multilist: "below",
    grid: "below",
    listbox: "below",
    radio: "below"
  };

  bb.questionlabelgroup = function () {
    $(".group:not(:has(.bb-questionlabelgroup))>.bb-label").each(function () {
      var $this = $(this),
        control,
        input = $this.prev("[type=checkbox]"),
        isCheckBox = !!input.length,
        itype,
        idatatype,
        classes,
        questionandlabel;

      if (!isCheckBox)
        input = $this.nextUntil(
          ".bb-label, .bb-text, a, [type=checkbox], img, .clearfix," +
            "*[data-datatype]+ul"
        );

      control = input.data("control");
      if (control) {
        itype = control.controltype;
        idatatype = control.datatype;
      }
      classes = input.attr("class");

      if (!isCheckBox) {
        input.wrapAll(
          '<div class="bb-wraps-input bb-fields"' +
            ' data-fields="' +
            input.length +
            '"' +
            ' data-wraps-datatype="' +
            idatatype +
            '"' +
            ' data-wraps-controltype="' +
            itype +
            '"' +
            ' data-wraps-classes="' +
            classes +
            '"></div>'
        );
        input = input.parent("div");
      } else {
        input.addClass("bb-fields");
      }

      questionandlabel = $this.add(input);

      if (questionandlabel.length > 1)
        questionandlabel.wrapAll(
          "<div " +
            'data-layout-type="' +
            layoutmap[itype] +
            '" ' +
            'data-input-datatype="' +
            idatatype +
            '" ' +
            'class="bb-questionlabelgroup' +
            " bb-itype-" +
            itype +
            " " +
            "bb-fields-" +
            questionandlabel.length +
            '"></div>'
        );

      var parent = questionandlabel.parents(".bb-questionlabelgroup");
      parent.append('<div class="clearfix"></div>');
      $.each(parent.find("[data-type]"), function () {
        var $this = $(this);
        if ($this.attr("class")) {
          var classname = $this.attr("class").match(/\bbbm-[a-z0-9-]+\b/);
          if (classname) {
            classname = classname[0].replace(/\bbbm-/, "bb-g-");
            parent.addClass(classname);
          }
        }
      });
      // Propagate tags of all children to parent
      $.each(parent.find("*"), function (i, elt) {
        $.each($(elt).data("tags") || [], function (i, tag) {
          parent.addClass("bb-child-tagged-" + tag);
        });
      });
    });
  };
})(jQuery);
