import { Mustache } from "$mustache";
import { converter } from "$md-converter";
import { bb } from "$json";
/* info-template:
 *
 * Render infolinks according to Mustache template
 *
 * @dependencies: Mustache
 *
 * Author: Niels Giesen
 * Copyright 2016 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  $(function () {
    var tmpl = $("#bb-p-info-template").html(),
      container = document.getElementById("bb-p-info-template-container");

    Mustache.tags = ["[[", "]]"];

    $(doc).on("bb:postHandleData", function (event, data) {
      var html, sources;
      if (container && tmpl && data && data.informationsources) {
        if (data.informationsources.length) {
          sources = $.map(data.informationsources, function (source) {
            var xss = source.isurl && bb.URL.isJS(source.content);
            return {
              isurl: source.isurl && !xss,
              description: source.description,
              content: xss
                ? bb.URL.JSAlertMessage
                : source.isurl
                  ? source.content
                  : (converter &&
                      converter.makeHtml(bb.escapeHTML(source.content))) ||
                    bb.escapeHTML(source.content)
            };
          });
          html = Mustache.render(tmpl, { sources: sources });
        } else {
          html = "";
        }
        container.innerHTML = html;
      }
    });
  });
})(jQuery, window, document);
