import { bb } from "$json";
/* has-no-next:
 *
 * Set body.hasNoNext when the current screen title matches a
 * predefined regular expression, and unsets it when does not.
 *
 * Author: Niels Giesen
 * Adapted by: Tim Bauwens
 *
 * Copyright (C) 2011 - 2017 by Berkeley Bridge
 *
 */

(function ($) {
  // Array to test absolute screentitles against:
  var endNodes = ["Einde", "Resultaat"];

  $(document).on("bb:postHandleData", function (event, data, status, req) {
    if (data && data.jumplist)
      bb.Mode.toggle(
        "hasNoNext",
        data.hasnext === false ||
          $.grep(data.jumplist, function (group) {
            return (
              group.status === "selected" &&
              endNodes.indexOf($.trim(group.screentitle)) > -1
            );
          }).length > 0
      );
  });
})(jQuery);
