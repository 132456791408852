/* model-template:
 *
 * Render models using a template in the page itself
 *
 * Author: Niels Giesen
 * Copyright 2015 Berkeley Bridge
 *
 * @dependencies: Mustache
 *
 * Usage:
 *
 * Include a Mustache template in template.html, such as this one
 * (implementing the default, i.e. the model code output without this
 * plugin):

   {{#plugins.model-template}}
    <script type="x-tmpl-mustache" id="bb-p-model-template">
      <tr class="bb-model bb-yours-[[yours]]" id="model[[dbname]]" data-bb:dbname="[[dbname]]">
        <td><button class="bb-model-name bb-model-cases">[[_nicename]]</button></td>
        <td><button class="bb-newcase" data-bb:dbname="[[dbname]]">{{model-template.new}}</button></td>
      </tr>
    </script>
    {{/plugins.model-template}}

* Or a more intricate one as the one below

   {{#plugins.model-template}}
    <script type="x-tmpl-mustache" id="bb-p-model-template">
      <button data-bb:dbname="[[dbname]]"
              itemscope
              class="bb-model bb-newcase [[_selectedclass]] bb-model-yours-[[yours]]">
        <span itemprop="name">
          [[_nicename]]
        </span>
        [[^completed]]
        <span>(Draft)</span>
        [[/completed]]
        <time itemprop="lastUpdate"
              datetime="[[_date]]">[[_humandate]]</time>
        [[^yours]]      <!-- Only show author info for others -->
        [[#authorname]] <!-- Only show author info when present -->
        <h4>author: <span itemprop="author">[[authorname]]</span></h4>
        [[#authorinfo]]
        <p itemprop="authorinfo">
          [[#_author_notes]]
          [[.]]<br/>
          [[/_author_notes]]
        </p>
        [[/authorinfo]]
        [[/authorname]]
        [[/yours]]
        [[#modelinfo]]
        <p itemprop="info">
          [[#_liner_notes]]
          [[.]]<br/>
          [[/_liner_notes]]
        </p>
        [[/modelinfo]]
      </button>
    </script>
    {{/plugins.model-template}}

*/

import { bb } from "$json";
import Mustache from "$mustache";

var tmpl;
bb.createModelItem = function (model) {
  return Mustache.render(tmpl, model);
};

$(function () {
  tmpl = $("#bb-p-model-template").html();
  Mustache.tags = ["[[", "]]"];
});
