import { bb, _ } from "$json";
/** xml-params.js:
 *
 * Let user start a new case with custom parameters read from an XML file
 *
 * The XSLT file creating the parameters does the following:
 *
 * - Use root element's name as parameter prefix
 * - For any of its direct children:
 *   - when they have children of their own:
 *     - aggregate any sibling with the same local-name()
 *     - for each of those (and self): render children as csv cells in a csv row
 *   - when leaf node: key=value pair (no uniquifying taking place here)
 *
 * Configuration:
 * ==============
 * Set the models you want to include as an array of strings in
 * arbitrary.xml-params.models or set it to the keyword "all".
 *
 *
 * Author: Niels Giesen
 * Copyright 2017 Berkeley Bridge
 *
 */
(function ($, win, doc) {
  var domParser,
    xsltproc,
    parsererrorNS,
    fragmentOwner,
    models,
    version = 5;

  if (
    "DOMParser" in window &&
    ("XSLTProcessor" in window || "ActiveXObject" in window) &&
    "FileReader" in window
  ) {
    try {
      models = bb.conf.arbitrary["xml-params"].models;
    } catch (e) {
      throw errNoModels;
    }
    if (models === undefined || (!Array.isArray(models) && models !== "all")) {
      throw errNoModels;
    }

    // Add some logic to models, telling them xml params may be loaded
    $(doc).on("bb:preHandleData", function (event, data) {
      if (data && data.models) {
        data.models.map(function (model) {
          if (models === "all" || ~models.indexOf(model.realmodelname)) {
            model["xml-params"] = true;
          }
        });
      }
    });

    $(doc).on("click", ".p-xml-params-load-stylesheet", preLoadStyleSheet);
    $(doc).on("change", ".p-xml-params-file", onupload);
  }

  var errNoModels = new Error(
    'No "arbitrary.xml-params.models" if conf file, or of wrong type (Array of strings expected or the keyword "all")'
  );

  function initialize() {
    domParser = domParser || new self.DOMParser();
    fragmentOwner =
      fragmentOwner || domParser.parseFromString("<root></root>", "text/xml");
    try {
      parsererrorNS = domParser
        .parseFromString("INVALID", "text/xml")
        .getElementsByTagName("parsererror")[0].namespaceURI;
    } catch (e) {
      // IE: ignore error, since the real one will throw anyway
    }
  }

  // Minimal bridge between MSXSL (IE10 and IE11) and Modern
  // XSLTProcessor, for use in this scenario only
  function MSXSLTProcessor() {
    this.thing = new self.ActiveXObject("Msxml2.XSLTemplate");
    return this;
  }
  MSXSLTProcessor.prototype.importStylesheet = function (doc) {
    var xml = new self.ActiveXObject("Msxml2.FreeThreadedDOMDocument");
    xml.load(doc);
    this.thing.stylesheet = xml;
    this.processor = this.thing.createProcessor();
  };
  MSXSLTProcessor.prototype.setParameter = function (ns, param, value) {
    this.processor.addParameter(param, value);
  };
  MSXSLTProcessor.prototype.transformToFragment = function (xml) {
    this.processor.input = xml;
    this.processor.transform();
    return { textContent: this.processor.output };
  };

  function loadStyleSheet(cb) {
    if (xsltproc) return cb();
    var options = {
      url: "plugins/xml-params/live/xml-params.xsl?v=" + version,
      dataType: "xml",
      error: function () {
        // console.log(arguments);
      },
      cache: true
    };
    $.ajax(options).done(function (xml) {
      if (xml) {
        xsltproc = self.XSLTProcessor
          ? new self.XSLTProcessor()
          : new MSXSLTProcessor();
        try {
          xsltproc.importStylesheet(xml);
          xsltproc.setParameter(null, "param-separator", "&");
          xsltproc.setParameter(null, "param-separator-encoded", "%20");
        } catch (e) {
          console.warn(e.message);
          xsltproc = undefined;
        } finally {
          if (xsltproc) cb();
        }
      }
    });
    return true;
  }

  function preLoadStyleSheet() {
    loadStyleSheet($.noop);
  }

  function tryParseXML(xmlString) {
    var dom = domParser.parseFromString(xmlString, "text/xml");
    if (
      typeof parsererrorNS !== "undefined" &&
      dom.getElementsByTagNameNS(parsererrorNS, "parsererror").length > 0
    ) {
      throw new SyntaxError("SyntaxError");
    }
    return dom;
  }

  function onupload(evt) {
    var target = evt.target,
      file = target.files[0], // FileList object
      reader = new window.FileReader();

    reader.onload = function (e) {
      initialize();
      // Read text file.
      var text = e.target.result,
        xml;
      try {
        xml = tryParseXML(text, "text/xml");
      } catch (e) {
        if (e.name !== "SyntaxError") throw e;
        bb.notify(
          _("Trouble reading the document, is it really an XML document?")
        );
      }
      if (xml)
        loadStyleSheet(function () {
          var out = xsltproc.transformToFragment(xml, fragmentOwner),
            data =
              $.param({
                dbname: target.getAttribute("data-dbname"),
                uniqueid: bb.getVar("uniqueid"),
                step: "open",
                fmt: "json"
              }) +
              "&" +
              out.textContent;
          bb.ajax.post({ data: data });
        });
    };
    reader.readAsText(file);
  }
})(jQuery, window, document);
